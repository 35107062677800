import './styles.scss';
import { carouselCustomResponseHandler } from './carouselResponse';

/**
 * Injects a web chat instance into the page.
 */
function addWebChat() {
  // This is the standard web chat configuration object. You can modify these values with the embed code for your
  // own assistant if you wish to try this example with your assistant. You can find the documentation for this at
  // https://web-chat.global.assistant.watson.cloud.ibm.com/docs.html?to=api-configuration#configurationobject.
  const options = {
    integrationID: 'b149360c-1f54-40a0-9386-6fb0bf70dd6c',
    region: 'au-syd',
    serviceInstanceID: 'd6ff7c10-7182-41bd-bc82-34cd3a4c1b27',
    onLoad: webChatOnLoad,
  };

  window.watsonAssistantChatOptions = options;

  const script = document.createElement('script');
  const version = options.clientVersion || 'latest';
  script.src = `https://web-chat.global.assistant.watson.appdomain.cloud/versions/${version}/WatsonAssistantChatEntry.js`;
  document.head.appendChild(script);
}

/**
 * This function is called when web chat has been loaded to change the greeting message.
 */
function customUpdateLauncherGreetingMessage(
  updateLauncherGreetingMessage = () => {}
) {
  const messages = [
    {
      lang: "English",
      text: "Ready to enhance your operations with AI?"
    },
    {
      lang: "Arabic",
      text: "هل أنت مستعد لتحسين عملياتك بالذكاء الاصطناعي؟"
    },
    {
      lang: "German",
      text: "Möchten Sie Ihre Abläufe mit KI optimieren?"
    },
    {
      lang: "French",
      text: "Prêt à améliorer vos opérations avec l'IA?"
    },
    {
      lang: "Italian",
      text: "Pronti a migliorare le vostre operazioni con l'IA?"
    },
    {
      lang: "Spanish",
      text: "¿Listo para mejorar tus operaciones con IA?"
    }
  ];

  const launcherContainerElement = document.querySelector(
    ".WACLauncherComplex__Container"
  );

  const launcherTextElement = document.querySelector(
    ".WACLauncherComplex__Text"
  );

  if (launcherContainerElement) {
    launcherContainerElement.style.maxWidth = "300px";
  }

  let messageIndex = 0;

  setInterval(() => {
    const { text, lang } = messages[messageIndex];
    updateLauncherGreetingMessage(text);
    messageIndex = (messageIndex + 1) % messages.length;

    if (launcherTextElement) {
      launcherTextElement.style.textAlign =
        lang === "Arabic" ? "right" : "left";
    }
  }, 4000); // Change message every 4 seconds
}

/**
 * This function is called when web chat has been loaded and is ready to be displayed.
 */
async function webChatOnLoad(instance) {
  instance.on({
    type: 'customResponse',
    handler: (event, instance) => {
      // The "user_defined_type" property is just an example; it is not required. You can use any other property or
      // condition you want here. This makes it easier to handle different response types if you have more than
      // one custom response type.
      if (event.data.message.user_defined && event.data.message.user_defined.user_defined_type === 'carousel') {
        carouselCustomResponseHandler(event, instance);
      }
    },
  });
  await instance.render();

  // Change the launcher message after the chat loads
  customUpdateLauncherGreetingMessage(instance.updateLauncherGreetingMessage);
}

// Launch web chat as soon as this script has loaded.
addWebChat();